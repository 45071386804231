body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#postMeta{
  font-family: Raleway;
  color: white;
  text-align: left;
}

#postItem{
  width: 60%;
  min-height: 150px;
  border-radius: 15px;
  background-color: #101010;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 20px;
  padding: 0px 40px;
}

#postContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
